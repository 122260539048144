import React from "react";

const ClassBadge = ({ label, text }) => {
  return text ? (
    <div
      className="p-2 shadow rounded-lg mr-2 mb-2"
      style={{
        boxShadow: "0px 2px 8px rgba(157, 186, 199, 0.25)",
        borderRadius: "16px",
      }}
    >
      <p className="text-gray font-avenir900 text-xs md:text-sm mb-1 whitespace-nowrap">
        {label}
      </p>
      <p className="text-body font-avenir800 text-sm md:text-base whitespace-nowrap">
        {text}
      </p>
    </div>
  ) : null;
};

export default ClassBadge;
