import React from "react";

const Arrow = ({ color = "#FC7979", className}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6151 23.7667C28.834 24.5302 27.5677 24.5302 26.7867 23.7667L17.1004 14.2983L7.41407 23.7667C6.63302 24.5302 5.36669 24.5302 4.58564 23.7667C3.80459 23.0033 3.80459 21.7654 4.58564 21.0019L17.1004 8.7687L29.6151 21.0019C30.3961 21.7654 30.3961 23.0033 29.6151 23.7667Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;
