import React from "react";

const RightArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-1"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 12C4 11.7348 4.10332 11.4805 4.28723 11.293C4.47114 11.1055 4.72058 11.0002 4.98067 11.0002H17.6502L13.4393 6.70896C13.2551 6.52122 13.1517 6.26659 13.1517 6.00108C13.1517 5.73558 13.2551 5.48095 13.4393 5.29321C13.6234 5.10547 13.8732 5 14.1336 5C14.394 5 14.6437 5.10547 14.8279 5.29321L20.7119 11.2921C20.8032 11.385 20.8757 11.4953 20.9251 11.6168C20.9746 11.7383 21 11.8685 21 12C21 12.1315 20.9746 12.2617 20.9251 12.3832C20.8757 12.5047 20.8032 12.615 20.7119 12.7079L14.8279 18.7068C14.6437 18.8945 14.394 19 14.1336 19C13.8732 19 13.6234 18.8945 13.4393 18.7068C13.2551 18.5191 13.1517 18.2644 13.1517 17.9989C13.1517 17.7334 13.2551 17.4788 13.4393 17.291L17.6502 12.9998H4.98067C4.72058 12.9998 4.47114 12.8945 4.28723 12.707C4.10332 12.5195 4 12.2652 4 12Z"
        fill="#07BBE3"
      />
    </svg>
  );
};

export default RightArrow;
