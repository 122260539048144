import React, { useState } from "react";
import QuestionMark from "../../../images/icons/QuestionMark.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WhiteGift from "../../../images/icons/WhiteGift.svg";
import GreenWater from "../../../images/icons/GreenWater.svg";
import GreenScissors from "../../../images/icons/GreenScissors.svg";
import GreenSun from "../../../images/icons/GreenSun.svg";
import GreenTemperature from "../../../images/icons/GreenTemperature.svg";
import GreenFertilize from "../../../images/icons/GreenFertilize.svg";
import { Accordion } from "../../Accordion";

const FAQS = ({ plant }) => {
  const Questions = [
    {
      question: plant?.Immersion_FAQ_Question_1,
      answer: plant?.Immersion_FAQ_Answer_1,
    },
    {
      question: plant?.Immersion_FAQ_Question_2,
      answer: plant?.Immersion_FAQ_Answer_2,
    },
    {
      question: plant?.Immersion_FAQ_Question_3,
      answer: plant?.Immersion_FAQ_Answer_3,
    },
    {
      question: plant?.Immersion_FAQ_Question_4,
      answer: plant?.Immersion_FAQ_Answer_4,
    },
    {
      question: plant?.Immersion_FAQ_Question_5,
      answer: plant?.Immersion_FAQ_Answer_5,
    },
  ];

  const FaqData = [
    {
      id: 1,
      faq: Questions,
      title: "General",
      icon: WhiteGift,
    },
    {
      id: 2,
      faq: plant?.Care_Water_FAQs,
      title: "Watering",
      icon: GreenWater,
    },
    {
      id: 3,
      faq: plant?.Care_potting_FAQs,
      title: "Potting ",
      icon: GreenScissors,
    },
    {
      id: 4,
      faq: plant?.Care_Light_FAQs,
      title: "Sunlight",
      icon: GreenSun,
    },
    {
      id: 5,
      faq: plant?.Care_Temp_FAQs,
      title: "Temperature",
      icon: GreenTemperature,
    },
    {
      id: 6,
      faq: plant?.Care_fertilizer_FAQs,
      title: "Fertilizing",
      icon: GreenFertilize,
    },
  ];
  const [activeTab, setActiveTab] = useState(
    FaqData.find((item) => item.faq && item.faq?.[0]?.question)?.id
  );

  return (
    <div className="flex  flex-col items-center pt-5 md:pt-10">
      <div className="w-full xl:w-[1200px]">
        <section className="flex items-center p-3 mb-2">
          <LazyLoadImage className="h-8 w-8 mr-3" src={QuestionMark} />
          <p className="text-2xl text-avenir800 font-semibold md:pb-0">
            Questions about {plant?.common_name}
          </p>
        </section>
        {FaqData?.find((category) => category?.id === activeTab)?.faq?.map(
          (post, index) => (
            <Accordion
              key={index}
              slug={""}
              icon={""}
              iconColor={"black"}
              title={post.question}
              content={post.answer}
              // infoCards={item.infoCards}
              // recommendedImage={item.recommendedImage}
              // recommendedType={item.recommendedType}
              // recommendedValue={item.recommendedValue}
              defaultShow={index === 0}
              id={""}
            />
          )
        )}

        {/* <div>
          <div className="flex p-5 whitespace-pre-wrap md:grid overflow-scroll lg:overflow-hidden w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:px-5lg:py-5">
            {FaqData?.find((category) => category?.id === activeTab)?.faq?.map(
              (post, index) => (
                <div
                  key={index}
                  className="shrink-0 w-[90vw] h-full rounded-xl shadow-lg p-5 md:w-full  transition duration-300"
                >
                  <p className="text-avenir900 text-base w-full pt-3 font-bold md:text-xl">
                    {post.question}
                  </p>
                  <p className="text-[#2D3344] font-light mt-2 text-avenir400">
                    {post.answer}
                  </p>
                </div>
              )
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FAQS;
