import React, { useRef, useState } from "react";
import Arrow from "../Arrow";
import RightArrow from "../../assets/svg/RightArrow";
// import { navigate } from 'gatsby';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ALLOWED_PAGES } from "../../utils/constant";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RemoveFirstLastString } from "../../utils/utils";
export const Accordion = ({
  title = "",
  content = "",
  icon,
  infoCards = [],
  iconColor,
  recommendedImage,
  recommendedType,
  recommendedValue,
  defaultShow,
  slug,
  id,
}) => {
  const contentSpace = useRef(null);

  const [active, setActive] = useState(defaultShow || false);
  const [height, setHeight] = useState(defaultShow ? "100%" : "0px");
  const [rotate, setRotate] = useState(
    defaultShow
      ? "transform duration-700 ease rotate-180"
      : "transform duration-700 ease"
  );
  const [showFullText, setShowFullText] = useState(false);
  function toggleAccordion() {
    setActive((prev) => !prev);
    setHeight(active ? "0px" : `100%`);
    setRotate(
      active
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-180"
    );
  }
  return (
    <div className="flex md:gap-x-5 px-5 lg:px-0 w-full" id={id || ""}>
      <div
        className="w-full flex flex-col justify-between px-4 rounded-2xl mb-5 bg-white h-full transition-max-height duration-700 ease-in-out"
        style={{
          boxShadow: "0px 2px 8px rgba(157, 186, 199, 0.25)",
          borderRadius: "16px",
        }}
      >
        <button
          className="py-5 box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between"
          onClick={toggleAccordion}
        >
          <div>
            {!!icon && (
              <LazyLoadImage
                src={icon}
                alt="Chevron icon"
                className="inline-block mr-2"
              />
            )}
            <p className="font-avenir800 text-body inline-block text-footnote light">
              {title}
            </p>
          </div>
          <Arrow color={iconColor} className={`${rotate} inline-block`} />
        </button>
        <div
          ref={contentSpace}
          style={{ maxHeight: `${height}` }}
          className={`flex  flex-col duration-700 ease-in-out transition-max-height scroll-none`}
        >
          {active && (
            <>
              <hr className="mt-0 mb-3 border-lightGray/30" />
              <div className="flex flex-row justify-between flex-wrap">
                {infoCards?.map?.((item, index) => (
                  <InfoView
                    key={`info-card-${index}`}
                    icon={item?.icon}
                    color={iconColor}
                    label={item?.label}
                    range={item?.range}
                    progress={item?.progress}
                    progressText={item?.progressText}
                    recommendedText={item?.recommendedText}
                  />
                ))}
              </div>
              <p className="text-base whitespace-pre-line md:text-base font-avenir400 text-body my-3.5 ">
                {content?.length > 220
                  ? showFullText
                    ? RemoveFirstLastString(content?.replaceAll("\\n", "\n\n"))
                    : `${RemoveFirstLastString(
                        content.slice(0, 220)?.replaceAll("\\n", "\n\n")
                      )}...`
                  : content}
              </p>
            </>
          )}
          {active && (
            <div className="flex justify-end pb-5">
              <section className="flex">
                {ALLOWED_PAGES?.includes(slug) && (
                  <AnchorLink
                    to={`/care/how-to-care-for-${slug}/#${id}`}
                    className={`flex justify-center items-center	whitespace-nowrap  text-[#07BBE3] py-2 px-6 cursor-pointer `}
                  >
                    Learn more
                    <RightArrow />
                  </AnchorLink>
                )}
                {content?.length > 220 && (
                  <button
                    onClick={() => setShowFullText(!showFullText)}
                    className={`flex justify-center items-center whitespace-nowrap	bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-6 cursor-pointer w-32`}
                  >
                    Show {showFullText ? "less" : "more"}
                  </button>
                )}
              </section>
            </div>
          )}
        </div>
      </div>
      {/* <div
        className="w-full flex flex-col justify-between px-4 rounded-2xl mb-2  bg-white h-full transition-max-height duration-700 ease-in-out "
        style={{
          boxShadow: "0px 2px 8px rgba(157, 186, 199, 0.25)",
          borderRadius: "16px",
        }}
      >
        <div>
          <button className="hidden md:flex py-5 box-border appearance-none  focus:outline-none  items-center justify-between">
            <div>
              <LazyLoadImage
                src={icon}
                alt="Chevron icon"
                className="inline-block mr-2"
              />
              <p className="font-avenir800 text-body inline-block text-footnote light">
                {title}
              </p>
            </div>
            <Arrow
              color={iconColor}
              className={`${rotate} inline-block md:hidden`}
            />
          </button>
          <div
            className={`hidden md:flex flex-col justify-between transition-max-height duration-700 ease-in-out scroll-none`}
          >
            <div className=" h-full flex flex-col justify-between pb-3 md:pb-6 font-avenir400 text-body scroll-none">
              <div className="block my-3 sm:flex flex-row justify-between flex-wrap">
                {infoCards?.map?.((item, index) => (
                  <InfoView
                    key={`info-card-${index}`}
                    icon={item?.icon}
                    color={iconColor}
                    label={item?.label}
                    range={item?.range}
                    progress={item?.progress}
                    progressText={item?.progressText}
                    recommendedText={item?.recommendedText}
                  />
                ))}
              </div>
              <p className="text-base whitespace-pre-line md:text-base font-avenir400 text-body my-3.5 ">
                {content?.length > 220
                  ? showFullText
                    ? RemoveFirstLastString(content?.replaceAll("\\n", "\n\n"))
                    : `${RemoveFirstLastString(
                        content.slice(0, 220)?.replaceAll("\\n", "\n\n")
                      )}...`
                  : content}
              </p>
            </div>
            {ALLOWED_PAGES?.includes(slug) && (
              <div className="flex justify-end pb-5">
                <section className="flex">
                  <AnchorLink
                    to={`/care/how-to-care-for-${slug}/#${id}`}
                    // onClick={() => navigate(`/care/how-to-care-for-${slug}`)}
                    className={`flex justify-center items-center	whitespace-nowrap  text-[#07BBE3] py-2 px-6 cursor-pointer `}
                  >
                    Learn more
                    <RightArrow />
                  </AnchorLink>
                  {content?.length > 220 && (
                    <button
                      onClick={() => setShowFullText(!showFullText)}
                      className={`flex justify-center items-center whitespace-nowrap	bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-6 cursor-pointer w-32`}
                    >
                      Show {showFullText ? "less" : "more"}
                    </button>
                  )}
                </section>
              </div>
            )}
          </div>
        </div>
      </div> */}

      <div className="relative h-full">
        <img
          className=" hidden md:block transition-opacity duration-500 "
          style={{
            maxWidth: active ? "390px" : 0,
            opacity: active ? 1 : 0,
          }}
          src={recommendedImage}
        />
        <div
          className={` hidden ${
            active
              ? "md:absolute md:block md:bottom-[20px] md:left-[30px] md:w-[112px] md:whitespace-nowrap md:w-1/3"
              : ""
          }`}
          style={{
            opacity: active ? 1 : 0,
          }}
        >
          <p className="font-avenir800 text-body  inline-block text-footnote light">
            {recommendedType}
          </p>
          <p
            className={`font-avenir800 text-xl text-transparent text-footnote text-center ${recommendedValue?.color} `}
          >
            {recommendedValue?.value}
          </p>
        </div>
      </div>
    </div>
  );
};

export const InfoView = ({
  icon,
  label = "",
  recommendedText = "",
  progress = 0,
  progressText = "",
  color,
  range = {}, //start, end, min, max
}) => {
  if (progress) {
    return (
      <div className="w-full text-center mb-2">
        <div className=" w-full h-5 bg-lightGray/30 rounded-full">
          <div
            className="h-full text-center text-xs font-avenir900 text-white rounded-full py-1.5"
            style={{
              width: `${progress}%`,
              backgroundColor: color,
            }}
          >
            {`${progress}%`}
          </div>
        </div>
        <span className="font-avenir800 text-md" style={{ color }}>
          {progressText}
        </span>
      </div>
    );
  }

  if (Object.keys(range).length) {
    const rangeDomain = range?.max - range?.min;
    const startPercentage =
      ((range?.start - range?.min) / rangeDomain) * 100 - 5;
    const endPercentage =
      100 - ((range?.end - range?.min) / rangeDomain) * 100 - 5;

    return (
      <div className="w-full text-center mb-2">
        <div className="flex flex-row justify-between align-center">
          <span className="text-gray font-avenir400 text-xs">
            {Math.abs(range?.min)}°
          </span>
          <span className="text-gray font-avenir400 text-xs">
            {Math.abs(range?.max / 2)}°
          </span>
          <span className="text-gray font-avenir400 text-xs">
            {Math.abs(range?.max)}°
          </span>
        </div>
        <div className=" w-full h-5 bg-lightGray/30 rounded-full">
          <div
            className="h-full text-center text-xs font-avenir900 text-white rounded-full py-1.5"
            style={{
              marginLeft: `${startPercentage}%`,
              marginRight: `${endPercentage}%`,
              backgroundColor: color,
            }}
          >
            {`${range?.start}° - ${range?.end}°`}
          </div>
        </div>
        <span className="font-avenir800 text-md" style={{ color }}>
          {progressText}
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-row mb-2 w-3/2 sm:w-1/2 ">
      <LazyLoadImage
        src={icon}
        alt="Chevron icon"
        className="inline-block mr-2"
      />
      <div>
        <p className="font-avenir800 text-body text-sm">{label}</p>
        <p className="font-avenir900 text-gray text-xs ">{recommendedText}</p>
      </div>
    </div>
  );
};
