import React, { useMemo, useState } from "react";
import TempLogo from "../../images/icons/temp.svg";
import CloudLogo from "../../images/icons/cloud.svg";
import StarLogo from "../../images/icons/star.svg";
import WaterLogo from "../../images/icons/water.svg";
import BlueCalendar from "../../images/icons/blueCalendar.svg";
import BlueCup from "../../images/icons/blueCup.svg";
import OrangeCalendar from "../../images/icons/orangeCalendar.svg";
import YellowCalendar from "../../images/icons/yellowCalendar.svg";
import SunOutline from "../../images/icons/sunOutline.svg";
import SoilPot from "../../images/icons/soilPot.svg";
import SunLogo from "../../images/icons/sun.svg";
import SoilLogo from "../../images/icons/soil.svg";
import HumidityLogo from "../../images/icons/humidity.svg";
import PetLogo from "../../images/icons/pet.svg";
import FertilizingLogo from "../../images/icons/fertilizing.svg";
import ReporttingLogo from "../../images/icons/repotting.svg";
import MemberBadge from "../MemberBadge";
import RecommendedTemperature from "../../images/icons/recommendedTemperature.svg";
import RecommendedSunLight from "../../images/icons/recommendedSunLight.svg";
import RecommendedWater from "../../images/icons/recommendedWater.svg";
import RecommendedHumidity from "../../images/icons/recommendedHumidity.svg";
import RecommendedFertilizer from "../../images/icons/recommendedFertilizer.svg";
import RecommendedRepotting from "../../images/icons/recommendedRepotting.svg";
import RecommendedSoil from "../../images/icons/recommendedSoil.svg";
// import BlogFooter from "../Layout/BlogFooter";
import Post from "../../images/icons/Post.svg";
import WhiteRightArrow from "../../images/icons/WhiteRightArrow.svg";
// import WhiteCloud from "../../images/icons/WhiteCloud.svg";
import { Accordion } from "../Accordion";
import Layout from "../Layout";
import ClassBadge from "../ClassBadge";
import DownloadAppBar from "../Layout/DownloadAppBar";
// import { Helmet } from "react-helmet";
import BreadCrumb from "../UI/BreadCrumb";
import RelatedPlants from "./RelatedPlants";
import { Link, graphql, navigate } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import PlantPlaceholder from "../../images/PlantPlaceholder.png";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../UI/Button";
import FAQS from "./FAQs";
// import Care from "../Care";
import Toxicity from "./Toxicity";
import { ALLOWED_PAGES, SHOW_ANNOUCEMENT_BANNER } from "../../utils/constant";
import CommonDiseases from "../Care/commonDiseases";
import ShopPlants from "./ShopPlants";

const PlantDetail = (props) => {
  const plant = props?.data?.allPlants?.edges?.[0]?.node;
  const careData = props?.data?.allCarePlants?.edges?.[0]?.node;
  const customRelatedPlants = props?.data?.relatedPlants?.edges;

  const blogs = props?.data?.allGraphCmsPost?.edges?.map((edge) => {
    return edge?.node;
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  let label = {
    common_name: "Common Name",
    genus: "Genus",
    family: "Family",
    order: "Order",
    species: "Species",
  };
  const classificationBadges = useMemo(
    () => [
      {
        label: "common_name",
      },
      {
        label: "genus",
      },
      {
        label: "family",
      },
      {
        label: "order",
      },
      {
        label: "species",
      },
    ],
    [plant]
  );

  const badges = useMemo(
    () => [
      {
        icon: StarLogo,
        label: "Level",
        text: `${plant?.overviewLevel}`,
        background: `linear-gradient(135deg, rgba(135, 225, 50, 0.15) 0%, rgba(42, 194, 213, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        anchorLink: "water",
        icon: WaterLogo,
        label: "Water",
        text: `${plant?.avgWaterDays} days`,
        background: `linear-gradient(94.5deg, rgba(54, 213, 249, 0.15) 0%, rgba(21, 171, 235, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        anchorLink: "temperature",
        icon: TempLogo,
        label: "Temperature",
        text: `${plant?.overviewMinTemp}° - ${plant?.overviewMaxTemp}°`,
        background: `linear-gradient(94.5deg, rgba(254, 85, 88, 0.15) 0%, rgba(234, 70, 133, 0.15) 100%),
         linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        anchorLink: "light",
        icon: SunLogo,
        label: "Light",
        text: plant?.overviewLight,
        background: `linear-gradient(113.96deg, rgba(252, 212, 57, 0.15) 0%, rgba(244, 182, 52, 0.15) 100%),
         linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        anchorLink: "humidity",
        icon: HumidityLogo,
        label: "Humidity",
        text: plant?.overviewMaxHumid,
        background: `linear-gradient(94.5deg, rgba(1, 201, 144, 0.15) 0%, rgba(23, 204, 222, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
      {
        icon: PetLogo,
        label: "Toxicity",
        text: plant?.toxic === "TRUE" ? "Toxic" : "Not Toxic",
        background: `linear-gradient(94.5deg, rgba(119, 216, 24, 0.15) 0%, rgba(46, 233, 165, 0.15) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
      },
    ],
    [plant]
  );

  const accordions = useMemo(
    () => [
      {
        icon: WaterLogo,
        iconColor: "#07BBE3",
        id: "water",
        title: "Watering & Moisture",
        content:
          plant?.Immersion_WaterDesc_nice_web || plant?.overviewWaterDesc,
        recommendedImage: RecommendedWater,
        recommendedType: "Water every",
        recommendedValue: {
          value: `${plant?.avgWaterDays} days`,
          color: "bg-clip-text bg-gradient-to-r from-[#36D5F9] to-[#15ABEB]",
        },
        defaultShow: true,
        infoCards: [
          {
            icon: BlueCalendar,
            label: `Every ${plant?.avgWaterDays}d`,
            recommendedText: "Recommended Frequency",
          },
          {
            icon: BlueCup,
            label: "1 /2  cup of water",
            recommendedText: "Recommended Amount",
          },
        ],
      },
      {
        icon: FertilizingLogo,
        iconColor: "#F2AE47",
        title: "Fertilizing",
        id: "fertilizing",
        content: plant?.Immersion_fertilizer_nice_web || plant?.fertilizer_desc,
        recommendedImage: RecommendedFertilizer,
        recommendedType: "Fertilize every ",
        defaultShow: true,
        recommendedValue: {
          value: `${plant?.avg_fertilizer_days} days`,
          color: "bg-clip-text bg-gradient-to-r from-[#FC7439] to-[#F4B334]",
        },

        infoCards: [
          {
            icon: OrangeCalendar,
            label: `Every ${plant?.avg_fertilizer_days}d`,
            recommendedText: "Recommended Frequency",
          },
          // {
          //   label: "1 or 2 cups of fertilizer",
          //   recommendedText: "Recommended Amount",
          // },
        ],
      },
      {
        icon: SunLogo,
        iconColor: "#EDC829",
        title: "Light",
        id: "light",
        content:
          plant?.Immersion_LightDesc_nice_web || plant?.overviewLightDesc,
        recommendedImage: RecommendedSunLight,
        recommendedType: "Light",
        recommendedValue: {
          value: plant?.overviewLight,
          color: "bg-clip-text bg-gradient-to-r from-[#FCD439] to-[#F4B634]",
        },
        defaultShow: true,
        infoCards: [
          {
            icon: SunOutline,
            label: plant?.overviewLight,
            recommendedText: "Recommended",
          },
        ],
      },
      {
        icon: ReporttingLogo,
        iconColor: "#EDC829",
        title: "Repotting",
        id: "repotting",
        content: plant?.Immersion_repotting_nice_web || plant?.repotting_desc,
        recommendedImage: RecommendedRepotting,
        recommendedType: "Repot every  ",
        defaultShow: true,
        recommendedValue: {
          value: `${plant?.avg_repotting_day} days`,
          color: "bg-clip-text bg-gradient-to-r from-[#FCD439] to-[#F4B634]",
        },

        infoCards: [
          {
            icon: YellowCalendar,
            label: `Every ${plant?.avg_repotting_day}d`,
            recommendedText: "Recommended Frequency",
          },
          // {
          //   label: "Small (3-6 in)",
          //   recommendedText: "Recommended Pot size",
          // },
          // {
          //   label: "Terracotta",
          //   recommendedText: "Recommended Pot type",
          // },
        ],
      },
      {
        icon: TempLogo,
        iconColor: "#FC7979",
        title: "Temperature",
        id: "temperature",
        content: plant?.Immersion_Temp_nice_web || plant?.overviewTempDesc,
        recommendedImage: RecommendedTemperature,
        recommendedType: "Temprature",
        recommendedValue: {
          value: `${plant?.overviewMinTemp}° - ${plant?.overviewMaxTemp}°`,
          color: "bg-clip-text bg-gradient-to-r from-[#FE5558] to-[#EA4685]",
        },
        defaultShow: true,
        infoCards: [
          {
            range: {
              min: 0,
              max: 100,
              start: plant?.overviewMinTemp,
              end: plant?.overviewMaxTemp,
            },
            // progressText: 'Ideal Range',
          },
        ],
      },
      {
        icon: SoilLogo,
        iconColor: "#77D818",
        title: "Soil",
        id: "soil",
        content: plant?.Immersion_soil_nice_web || plant?.soil_type_description,
        recommendedImage: RecommendedSoil,
        recommendedType: "Soil",
        defaultShow: true,
        recommendedValue: {
          value: plant?.soil_type || `-`,
          color: "bg-clip-text bg-[#87E132]",
        },
        infoCards: [
          {
            icon: SoilPot,
            label: plant?.soil_type,
            recommendedText: "Recommended Soil",
          },
        ],
      },
      {
        icon: CloudLogo,
        iconColor: "#00C9A4",
        id: "humidity",
        title: "Humidity",
        content: plant?.Immersion_Humidity_nice_web || plant?.overviewHumidDesc,
        recommendedImage: RecommendedHumidity,
        recommendedType: "Humidity",
        recommendedValue: {
          value: `${plant?.overviewMaxHumid} %`,
          color: "bg-clip-text bg-gradient-to-r from-[#01C990] to-[#17CCDE]",
        },
        defaultShow: true,
        infoCards: [
          {
            progress: plant?.overviewMaxHumid,
            // progressText: 'Recommended Humidity',
          },
        ],
      },
    ],
    [plant]
  );
  const imageURLs = [
    plant?.imageURL,
    plant?.imageURL_1,
    plant?.imageURL_2,
    plant?.imageURL_3,
    plant?.imageURL_4,
    plant?.imageURL_5,
  ];

  // Filter out null or empty URLs
  const filteredImageURLs = imageURLs.filter((url) => url);

  return (
    <Layout
      title={`Flora | ${plant?.common_name}`}
      description={plant?.short_desc}
      name={plant?.common_name}
    >
      <div className="flex flex-col items-center ">
        <div
          className={`w-full xl:w-[1200px] ${
            SHOW_ANNOUCEMENT_BANNER ? "mt-28 md:mt-36" : "mt-20 md:mt-28"
          } `}
        >
          <main className="max-w-full px-5  lg:px-0">
            <BreadCrumb
              menu={[
                {
                  label: "Flora",
                  to: "/",
                },
                {
                  label: "Plant Care",
                  to: "/explore-plants",
                },
                {
                  label: plant?.common_name,
                },
              ]}
              type={1}
            />
            <section className="py-5 flex  flex-col md:flex-row ">
              <aside className="flex flex-col  justify-between w-full md:mr-5 md:w-1/3">
                <LazyLoadImage
                  className="rounded-3xl aspect-square w-full h-80 object-cover object-top"
                  alt="avatar"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      filteredImageURLs?.[currentImageIndex + 1] ||
                      PlantPlaceholder;
                  }}
                  src={filteredImageURLs?.[currentImageIndex]}
                />
                <div className="flex  gap-4 py-8 overflow-x-scroll md:py-4">
                  {filteredImageURLs.map((item, index) => {
                    return (
                      <LazyLoadImage
                        role="button"
                        tabIndex="0"
                        key={index}
                        className={`rounded-xl aspect-square w-20 h-20  md:w-20 object-cover ${
                          currentImageIndex === index
                            ? "border-green border-4"
                            : ""
                        }`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            filteredImageURLs?.[currentImageIndex + 1] ||
                            PlantPlaceholder;
                        }}
                        onClick={() => setCurrentImageIndex(index)}
                        alt="avatar"
                        src={item}
                      />
                    );
                  })}
                </div>
              </aside>
              <aside className="flex justify-between flex-col md:w-2/3">
                <p className="font-avenir800 text-3xl text-body sm:text-5xl mt-1 mb-1">
                  {plant?.common_name}
                </p>
                <p className="font-avenir900 text-lg sm:text-2xl text-gray">{`${plant?.common_name} - ${plant?.plant_types}`}</p>
                <p className="text-sm md:text-base font-avenir400 text-body mb-3.5 pr-10">
                  {plant?.Immersion_summary_web || plant?.short_desc}
                </p>
                <h1 className="font-avenir800 text-body text-lg mb-3 md:text-xl">
                  {`Scientific Classification`}
                </h1>
                <div className="flex flex-row flex-wrap pr-10">
                  {classificationBadges?.map((item, index) => (
                    <ClassBadge
                      key={index}
                      label={label[item.label]}
                      text={plant?.[item.label]}
                    />
                  ))}
                </div>
                <div className="grid grid-cols-3 gap-5 mt-2 md:grid-cols-6 md:py-4">
                  {badges.map((badge, index) => (
                    <MemberBadge
                      key={`badge-${index}`}
                      item={badge}
                      background={badge?.background}
                      anchorLink={badge?.anchorLink}
                    />
                  ))}
                </div>
              </aside>
            </section>
          </main>
        </div>
      </div>
      <section className="pt-7 bg-[#F1FFF7] py-5 md:py-10">
        <div className="flex flex-col items-center">
          <div className="w-full xl:w-[1200px]">
            <div className="flex justify-between pb-8 px-5 md:pb-8  lg:px-0">
              <section className="flex items-center ">
                <StaticImage
                  className="h-10 w-10 mr-2"
                  src="../../images/icons/bookBold.png"
                />
                <h2 className="text-2xl font-avenir800 font-semibold md:text-3xl">
                  Care Guides for {plant?.common_name}
                </h2>
              </section>
              {ALLOWED_PAGES?.includes(plant?.slug) && (
                <Link href={`/care/how-to-care-for-${plant?.slug}/`}>
                  <Button
                    frontIcon={Post}
                    backIcon={WhiteRightArrow}
                    label="See full care guide"
                    className="hidden md:block text-white px-5 py-2"
                  />
                </Link>
              )}
            </div>
            <div className="w-full flex flex-col items-start">
              {accordions.map((item, index) => {
                return (
                  <Accordion
                    slug={plant?.slug}
                    key={`accordion-item-${index}`}
                    icon={item.icon}
                    iconColor={item.iconColor}
                    title={item.title}
                    content={item.content}
                    infoCards={item.infoCards}
                    recommendedImage={item.recommendedImage}
                    recommendedType={item.recommendedType}
                    recommendedValue={item.recommendedValue}
                    defaultShow={item.defaultShow}
                    id={item?.id}
                  />
                );
              })}
              {ALLOWED_PAGES?.includes(plant?.slug) && (
                <Button
                  handleClick={() =>
                    navigate(`/care/how-to-care-for-${plant?.slug}/`)
                  }
                  frontIcon={Post}
                  backIcon={WhiteRightArrow}
                  label="See full care guide"
                  className="md:hidden text-white px-5 py-2 m-5 max-w-sm"
                />
              )}
            </div>
            {/* <div className="w-1/3 hidden sm:hidden md:block">
                  <div className="bg-[#F9FCFE] p-6 rounded-3xl">
                    <img
                      className="rounded-3xl aspect-square w-full object-cover"
                      alt="avatar"
                      src={plant?.imageURL}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PlantPlaceholder;
                      }}
                    />
                    <div className="mt-6">
                      {badges.map((badge, index) => (
                        <MemberBadge key={`badge-${index}`} item={badge} />
                      ))}
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </section>
      <ShopPlants
        plantName={plant?.common_name}
        selectedPlant={props?.data?.allShopifyProduct?.edges}
      />
      {careData?.Immersion_FAQ_Question_1 && <FAQS plant={careData} />}

      {careData?.Immersion_toxicity_human_standard_web && (
        <Toxicity plant={careData} />
      )}
      {/* <div className="p-5 flex justify-center md:py-10">
        <DownloadAppBar />
      </div> */}
      {careData?.Immersion_common_issue_1_nice_web && (
        <CommonDiseases plant={careData} fromCarePage={false} />
      )}
      <RelatedPlants
        customPlants={customRelatedPlants}
        paragraph={plant?.Immersion_related_plants_standard_web}
      />
      <div className="px-5 py-5 flex  flex-col items-center md:py-10">
        <div className="w-full xl:w-[1200px]">
          <p className="text-2xl pb-4">Other Articles:</p>
          <div className="flex md:grid overflow-scroll w-full  grid-cols-1 px-0 md:grid-cols-2  lg:grid-cols-4 gap-6 md:px-5 lg:px-0 lg:py-5">
            {(blogs || []).map((post, index) => (
              <div
                key={index}
                onClick={() => navigate(`/blogs/${post?.slug?.toLowerCase()}/`)}
                className="shrink-0 cursor-pointer w-[16rem]"
              >
                <LazyLoadImage
                  className="w-80 h-48 rounded-xl object-cover md:w-full hover:scale-95 transition duration-200 cursor-pointer"
                  src={post?.coverImage?.url}
                  alt={post?.coverImage?.fileName || "blog-feature-img"}
                />
                <p className="text-avenir900 text-base w-full pt-3 font-bold md:text-xl">
                  {post?.title}
                </p>
                <p className="text-[#2D3344] font-light mt-2">
                  {moment(post?.date).format("MMM DD, YYYY")}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
        <DownloadAppBar className="my-0 mx-auto" />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CarePlantBySlug(
    $slug: String!
    $overviewLevel: String!
    $toxic: String!
    $plantTypes: String!
    $commonName: String!
  ) {
    allPlants(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          common_name
          imageURL
          imageURL_1
          imageURL_2
          imageURL_3
          imageURL_4
          plant_types
          overviewLightDesc
          avgWaterDays
          short_desc
          overviewMinTemp
          overviewMaxTemp
          overviewLight
          overviewHumidDesc
          overviewMaxHumid
          overviewTempDesc
          overviewWaterDesc
          overviewLevel
          overviewWaterAmount
          soil_type_description
          soil_type
          repotting_desc
          fertilizer_desc
          avg_fertilizer_days
          repotting_desc
          avg_repotting_day
          toxic
          genus
          family
          slug
          family
          scientific_name
        }
      }
    }
    allCarePlants(filter: { plant_name_dash: { eq: $slug } }) {
      edges {
        node {
          imageURL
          common_name
          plant_name_dash
          Immersion_FAQ_Answer_1
          Care_introduction_nice
          Immersion_FAQ_Answer_1
          Immersion_FAQ_Answer_2
          Immersion_FAQ_Answer_3
          Immersion_FAQ_Answer_4
          Immersion_FAQ_Answer_5
          Immersion_FAQ_Question_1
          Immersion_FAQ_Question_2
          Immersion_FAQ_Question_3
          Immersion_FAQ_Question_4
          Immersion_FAQ_Question_5
          Immersion_summary_web
          Immersion_WaterDesc_nice_web
          Immersion_fertilizer_nice_web
          Immersion_LightDesc_nice_web
          Immersion_Humidity_nice_web
          Immersion_pruning_nice_web
          Immersion_repotting_nice_web
          Immersion_soil_nice_web
          Immersion_Temp_nice_web
          Immersion_related_plants_standard_web
          Immersion_toxicity_dog_standard_web
          Immersion_toxicity_cat_standard_web
          Immersion_toxicity_human_standard_web
          Care_fertilizer_nice_web
          Care_related_plants_standard_web
          Care_soil_nice_web
          Care_potting_mix_type_nice_web
          Care_Humidity_nice_web
          Care_temp_nice_web
          Care_LightDesc_nice_web
          Care_WaterDesc_nice_web
          Care_introduction_nice
          Care_Humidity_FAQs
          Care_Water_FAQs
          Care_Temp_FAQs
          Care_potting_FAQs
          Care_soil_FAQs
          Care_fertilizer_FAQs
          Care_Light_FAQs
          Care_common_issue_1_nice_web
          Care_common_issue_2_nice_web
          Care_common_issue_3_nice_web
          Care_common_issue_4_nice_web
          Immersion_common_issue_1_nice_web
          Immersion_common_issue_2_nice_web
          Immersion_common_issue_3_nice_web
          Immersion_common_issue_4_nice_web
        }
      }
    }
    allGraphCmsPost(limit: 8) {
      edges {
        node {
          title
          slug
          date
          coverImage {
            url
          }
        }
      }
    }
    relatedPlants: allPlants(
      skip: 0
      limit: 10
      filter: {
        overviewLevel: { eq: $overviewLevel }
        toxic: { eq: $toxic }
        plant_types: { eq: $plantTypes }
      }
    ) {
      edges {
        node {
          id
          plant_types
          imageURL
          scientific_name
          common_name
          slug
        }
      }
    }
    allShopifyProduct(
      filter: { productType: { eq: "Plants" }, title: { regex: $commonName } }
      sort: { fields: createdAt }
      limit: 10
    ) {
      edges {
        node {
          id
          title
          productType
          description
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH)
            altText
          }
          variants {
            price
          }
          onlineStoreUrl
        }
      }
    }
  }
`;

export default PlantDetail;
