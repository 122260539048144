import React from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import RedUser from "../../../images/icons/RedUser.png";
import RedDog from "../../../images/icons/RedDog.png";
import RedCat from "../../../images/icons/RedCat.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Toxicity = ({ plant }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  const ToxicData = [
    {
      id: 1,
      src: RedUser,
      title: "Slightly Toxic to Humans",
      description: plant?.Immersion_toxicity_human_standard_web,
    },
    {
      id: 2,
      title: "Toxic to Dogs",
      src: RedDog,
      description: plant?.Immersion_toxicity_dog_standard_web,
    },
    {
      id: 3,
      title: `Toxic to Cats`,
      src: RedCat,
      description: plant?.Immersion_toxicity_cat_standard_web,
    },
  ];
  return (
    <div className="flex flex-col items-center pt-10">
      <main className="w-full xl:w-[1200px]">
        <section className="flex items-center pt-3 px-5 lg:px-0">
          <StaticImage
            className="h-10 w-10 mr-3"
            src="../../../images/icons/Alert.png"
          />
          <p className=" text-2xl text-avenir800 font-semibold  md:pb-0 ">
            Toxicity of {plant?.common_name}
          </p>
        </section>
        <section>
          <Slider
            {...settings}
            className="flex h-full flex-row justify-between px-2 mx-2 md:px-0 md:mx-0"
          >
            {ToxicData?.map((item, index) => (
              <div key={item?.id}>
                <div className="bg-[#FFECEB] rounded-xl mx-2 p-5 my-5  md:my-8  lg:h-[26rem] md:mx-4 md:p-5 hover:shadow-lg">
                  <LazyLoadImage
                    src={item?.src}
                    alt={item?.title}
                    className="pb-3 w-12 h-14"
                  />
                  <h5 className="text-xl font-semibold pb-2">{item?.title}</h5>
                  <p className="text-md">{item.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </main>
    </div>
  );
};

export default Toxicity;
